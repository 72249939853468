// import { FirebaseProvider } from "./lib/FirebaseProvider";
import AnimatedBg from "./components/AnimatedBg";
import {
  BeachContainer,
  MenuLogo,
  MenuImage,
  BeachSlide,
  MenuContainer,
} from "./components/Containers";
import { beach1, beach2, logo, logoInv, menu, menuInv } from "./lib/Images";

const Menu = () => {
  // React.useEffect(() => {
  //   FirebaseProvider.analytics().setCurrentScreen("Home");
  // });

  return (
    <>
      <BeachContainer>
        <AnimatedBg className="beach" duration={30}>
          <BeachSlide image={beach1} />
          <BeachSlide image={beach2} />
        </AnimatedBg>
      </BeachContainer>
      <div>
        <AnimatedBg duration={15}>
          <MenuLogo image={logo} />
          <MenuLogo image={logoInv} />
        </AnimatedBg>
        <MenuContainer>
          <AnimatedBg duration={20}>
            <MenuImage image={menu} />
            <MenuImage image={menuInv} />
          </AnimatedBg>
        </MenuContainer>
      </div>
    </>
  );
};

export default Menu;
