import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/lazy";

import * as React from "react";
import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import required modules
type IAnimatedBgProps = {
  testId?: string;
  className?: string;
  children?: React.ReactNode[];
  duration?: number;
};

/**
 * Slides between children in an infinite loop.
 *
 * https://codesandbox.io/s/yrnj7h?file=/src/App.jsx:296-377
 * https://swiperjs.com/swiper-api
 *
 * @returns {React.ReactNode} Swiper
 */
const AnimatedBg: React.FunctionComponent<IAnimatedBgProps> = ({
  children,
  className,
  duration,
  ...rest
}) => {
  if (!children?.length) {
    return null;
  }

  return (
    <Swiper
      loop={true}
      effect="fade"
      spaceBetween={0}
      navigation={false}
      modules={[EffectFade, Autoplay]}
      className={className}
      autoplay={{
        delay: (duration || 40) * 1000,
        disableOnInteraction: false,
      }}
      {...rest}
    >
      {children.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AnimatedBg;
