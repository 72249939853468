import styled from "styled-components";

type ImageProp = { image: string };

export const BeachContainer = styled.div`
  .beach {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
  }
`;

export const BeachSlide = styled.div<ImageProp>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url(${(p) => p.image});
  position: absolute;
`;

export const MenuStyled = styled.header`
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white !important;
  display: grid;
  grid-auto-flow: row;
  strong {
    color: #1341c5 !important;
  }

  span {
    color: white !important;
  }
  bdt.question {
    color: yellow !important;
    strong  {
      color: #1341c5 !important;
    }
  }
`;

export const MenuContainer = styled.div`
  margin-top: -3rem;
`;

export const MenuImage = styled.div<ImageProp>`
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.9;
  width: 70%;
  height: 50rem;
  margin: auto;
  z-index: 5;
  background-image: url(${(p) => p.image});
`;

export const MenuLogo = styled.div<ImageProp>`
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 13rem;
  height: 8rem;
  margin: 0.5rem;
  z-index: 2;
  background-image: url(${(p) => p.image});
`;
