export const logo =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/lz.logo.png?alt=media&token=8adf572b-fd0d-42f2-b67f-a107b9041720";
export const logoInv =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/lz.logo.inv.png?alt=media&token=4ff5309b-3b16-404b-9579-40e1bac5b008";
export const beach1 =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/beach01.web.jpg?alt=media&token=780b0f67-acd1-41c3-8d8d-5ec742ec7306";
export const beach2 =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/beach02.web.jpg?alt=media&token=0aa05d0c-3fbb-4b9a-a50f-c48385451949";
export const menu =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/LZ.Nynas.Meny.A3.jpg?alt=media&token=df33ffac-7f19-44a7-b54e-8fdffead52de";
export const menuInv =
  "https://firebasestorage.googleapis.com/v0/b/lz-poke.appspot.com/o/LZ.Nynas.Meny.A3.inv.jpg?alt=media&token=7d5fd56f-66e2-4b60-a3a4-579e24f8f23a";
