import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { MenuStyled } from "./components/Containers";
import Menu from "./Menu";
import Privacy from "./Privacy";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <main>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/" element={<Menu />}></Route>
        </Routes>
        <footer>
          <MenuStyled>
            <Link to="/privacy" title="Sekretesspolicy LZ Poké Sushi">
              Sekretesspolicy
            </Link>
          </MenuStyled>
        </footer>
      </main>
    </BrowserRouter>
  );
};
